import * as types from '../constants';

const initialState = {
  drips: [],
  fullPage: false,
  perDrug: 4
};

const drips = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_DRIP:
      const drippp = action.payload;
      return {
        ...state,
        drips: {
          ...state.drips,
          [drip.id]: drip
        }
      };
    case types.HANDLE_FULLPAGE:
      const fullPage = action.payload;
      console.log('HANDLE_FULLPAGE');
      console.log('STATE');
      console.log(state);
      console.log('ACTION');
      console.log(action);
      return {
        ...state,
        fullPage: !state.fullPage
      };
    case types.HANDLE_PERDRUG:
      const perDrug = action.payload;
      console.log('HANDLE_PERDRUG');
      console.log('STATE');
      console.log(state);
      console.log('ACTION');
      console.log(action);
      return {
        ...state,
        perDrug: perDrug
      };
    case types.SELECT_DRIP:
      /**
        console.log("SELECT_DRIP")
        console.log("ACTION")
        console.log(action);
        console.log("STATE")
        console.log(state);
        **/
      const drip = action.payload;
      if (state.drips.find(e => e.id === drip.id) === undefined) {
        return {
          ...state,
          drips: [...state.drips, drip]
        };
      } else {
        return {
          ...state,
          drips: state.drips.filter(gtt => gtt.id !== drip.id)
        };
      }
    case types.DELETE_DRIP:
      return {
        ...state,
        drips: state.drips.filter(drip => drip.id !== action.payload)
      };
    case types.TOGGLE_DRIP:
      return state.map(drip => (drip.id === action.id ? { ...drip, completed: !drip.completed } : drip));
    default:
      return state;
  }
};

export default drips;

/**
 * handleSelectItem(e) {
    console.log(this.state);
    const { value } = e.target;
    const nextValue = this.getNextValue(value);

    //this.setState({ selectedDrips: nextValue, lastSelectedDrip: value });
    this.setState({ selectedDrips: nextValue, lastSelectedDrip: value }, () => { console.log(this.state) });
    //this.setState({ count: 5 }, () => { console.log(this.state.count) });
  }

  getNextValue(value) {
    const { isShiftDown, selectedDrips } = this.state;
    const hasBeenSelected = !selectedDrips.includes(value);

    if (isShiftDown) {
      const newselectedDrips = this.getNewselectedDrips(value);
      // de-dupe the array using a Set
      const selections = [...new Set([...selectedDrips, ...newselectedDrips])];

      if (!hasBeenSelected) {
        return selections.filter(item => !newselectedDrips.includes(item));
      }

      return selections;
    }

    // if it's already in there, remove it, otherwise append it
    return selectedDrips.includes(value)
      ? selectedDrips.filter(item => item !== value)
      : [...selectedDrips, value];
  }
 */
