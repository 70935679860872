import * as types from '../constants';

export const handlePerDrug = perDrug => ({
  type: types.HANDLE_PERDRUG,
  payload: perDrug
});

export const handleFullPage = fullPage => ({
  type: types.HANDLE_FULLPAGE,
  payload: fullPage
});

export const handleSplitLabel = splitLabel => ({
  type: types.HANDLE_SPLITLABEL,
  payload: splitLabel
});
