import React, { useEffect } from "react";
import { Provider } from "react-redux";

import store from "./redux/store/index";
import Routes from "./routes/Routes";

const App = () => {
    const hideSplash = () => {
      !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
    }
    useEffect(() => { hideSplash() }, []);
    
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
};


export default App;
