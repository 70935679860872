import * as types from '../constants';

const initialState = {
  fullPage: false,
  perDrug: 4,
  splitLabel: false
};

const options = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_FULLPAGE:
      const fullPage = action.payload;
      return {
        ...state,
        fullPage: !state.fullPage
      };
    case types.HANDLE_PERDRUG:
      const perDrug = action.payload;
      return {
        ...state,
        perDrug: perDrug
      };
    case types.HANDLE_SPLITLABEL:
      const splitLabel = action.payload;
      return {
        ...state,
        splitLabel: splitLabel
      };
    default:
      return state;
  }
};

export default options;
