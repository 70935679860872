import * as types from '../constants';

export const addDrip = drip => ({
  type: types.ADD_DRIP,
  payload: drip
});

export const selectDrip = drip => ({
  type: types.SELECT_DRIP,
  payload: drip
});

export const toggleDrip = id => ({
  type: types.TOGGLE_DRIP,
  payload: id
});

export const deleteDrip = id => ({
  type: types.DELETE_DRIP,
  payload: id
});
