// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE';
export const SIDEBAR_VISIBILITY_SHOW = 'SIDEBAR_VISIBILITY_SHOW';
export const SIDEBAR_VISIBILITY_HIDE = 'SIDEBAR_VISIBILITY_HIDE';
export const SIDEBAR_RIGHT_ENABLE = 'SIDEBAR_RIGHT_ENABLE';
export const SIDEBAR_RIGHT_DISABLE = 'SIDEBAR_RIGHT_DISABLE';

// Theme
export const THEME_TOGGLE = 'THEME_TOGGLE';

// DRIPS

export const ADD_DRIP = 'ADD_DRIP';
export const SELECT_DRIP = 'SELECT_DRIP';
export const DELETE_DRIP = 'DELETE_DRIP';
export const TOGGLE_DRIP = 'TOGGLE_DRIP';

export const HANDLE_PERDRUG = 'HANDLE_PERDRUG';
export const HANDLE_FULLPAGE = 'HANDLE_FULLPAGE';
export const HANDLE_SPLITLABEL = 'HANDLE_SPLITLABEL';

export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
