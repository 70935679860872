import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { toggleDrip, addDrip, deleteDrip, selectDrip } from './actions/dripsActions';
import { handleFullPage, handlePerDrug, handleSplitLabel } from './actions/optionsActions';
import { showSidebar, hideSidebar, toggleSidebar } from './actions/sidebarActions';
import { setVisibilityFilter } from './actions/visibilityActions';
import { selectDisplayedDrips, selectVisibilityFilter, selectOptions, selectSidebar } from './selectors';

export const useDripsStore = () => {
  const dispatch = useDispatch();
  const drips = useSelector(selectDisplayedDrips);
  const visibilityFilter = useSelector(selectVisibilityFilter);

  const _addDrip = useCallback(name => dispatch(addDrip(name)), [dispatch]);
  const _setVisibilityFilter = useCallback(filter => dispatch(setVisibilityFilter(filter)), [dispatch]);
  const _selectDrip = useCallback(id => dispatch(selectDrip(id)), [dispatch]);
  const _toggleDrip = useCallback(id => dispatch(toggleDrip(id)), [dispatch]);
  const _deleteDrip = useCallback(id => dispatch(deleteDrip(id)), [dispatch]);

  return {
    drips,
    visibilityFilter,
    addDrip: _addDrip,
    setVisibilityFilter: _setVisibilityFilter,
    selectDrip: _selectDrip,
    toggleDrip: _toggleDrip,
    deleteDrip: _deleteDrip
  };
};

export const useOptionsStore = () => {
  const dispatch = useDispatch();
  const options = useSelector(selectOptions);
  const perDrug = options.perDrug;
  const fullPage = options.fullPage;
  const splitLabel = options.splitLabel;
  const _handleFullPage = useCallback(fullPage => dispatch(handleFullPage(fullPage)), [dispatch]);
  const _handlePerDrug = useCallback(perDrug => dispatch(handlePerDrug(perDrug)), [dispatch]);
  const _handleSplitLabel = useCallback(splitLabel => dispatch(handleSplitLabel(splitLabel)), [dispatch]);

  return {
    options,
    perDrug: perDrug,
    fullPage: fullPage,
    splitLabel: splitLabel,
    handleFullPage: _handleFullPage,
    handlePerDrug: _handlePerDrug,
    handleSplitLabel: _handleSplitLabel
  };
};

export const useSidebarStore = () => {
  const dispatch = useDispatch();
  const sidebar = useSelector(selectSidebar);
  const _hideSidebar = useCallback(id => dispatch(hideSidebar()), [dispatch]);
  const _showSidebar = useCallback(id => dispatch(showSidebar()), [dispatch]);
  const _toggleSidebar = useCallback(id => dispatch(toggleSidebar()), [dispatch]);

  return {
    sidebar: sidebar,
    hideSidebar: _hideSidebar,
    showSidebar: _showSidebar,
    toggleSidebar: _toggleSidebar
  };
};
