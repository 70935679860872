import React from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import { useDripsStore, useOptionsStore } from '../redux/index';
import PDFDocument from './PDFDocument';

const tableRowsCount = 5;
const tableColsCount = 4;
const tableFieldCount = tableColsCount * tableRowsCount;

const Printout = () => {
  const { options } = useOptionsStore();
  const { drips } = useDripsStore();
  const { fullPage, perDrug } = options;
  const selectedDrips = drips.drips;

  let numDrips = selectedDrips.length;
  let numFields = numDrips * perDrug;
  let numPages = 1;

  const generateLabels = () => {
    let labels = [];
    let tc = 0;
    if (numDrips > 0) {
      if (fullPage === true) {
        for (let i = 0; i < numDrips; i++) {
          for (let j = 0; j < tableFieldCount; j++) {
            let gtt = selectedDrips[i];
            let field = {
              fid: tc,
              name: gtt.name
            };
            labels.push(field);
            tc++;
          }
        }
      } else {
        let tlc = [];
        let numPages = numFields / tableFieldCount;
        if (numFields < tableFieldCount) {
          let numBlank = tableFieldCount - numFields;
          let blankFields = fillBlankSpace(numBlank);
          for (let i = 0; i < numDrips; i++) {
            for (let j = 0; j < perDrug; j++) {
              let gtt = selectedDrips[i];
              let field = {
                fid: tc,
                name: gtt.name
              };
              tlc.push(field);
              tc++;
            }
          }
          labels = [].concat(tlc, blankFields);
        } else if (numFields > tableFieldCount && numPages > 1) {
          let lbc = [];
          for (let i = 0; i < numDrips; i++) {
            for (let j = 0; j < perDrug; j++) {
              let gtt = selectedDrips[i];
              let field = {
                fid: tc,
                name: gtt.name
              };
              lbc.push(field);
              tc++;
            }
          }

          for (let p = 0; p < numPages; p++) {
            let begin = p * tableFieldCount;
            let end = (p + 1) * tableFieldCount;
            let lbcPage = lbc.slice(begin, end);
            let lbcNum = lbcPage.length;
            if (lbcNum < tableFieldCount) {
              let numBlank = tableFieldCount - lbcNum;
              let blankFields = fillBlankSpace(numBlank);
              labels = [].concat(lbc, blankFields);
            } else {
              labels = lbc;
            }
            //console.log(lbcPage);
          }
        } else {
          for (let i = 0; i < numDrips; i++) {
            for (let j = 0; j < perDrug; j++) {
              let gtt = selectedDrips[i];
              let field = {
                fid: tc,
                name: gtt.name
              };
              labels.push(field);
              tc++;
            }
          }
        }
      }
    }
    return labels;
  };

  const pageFromLabels = (p, labels) => {
    let rows = [];
    let li = 0;
    for (let i = 0; i < tableRowsCount; i++) {
      let cols = [];
      for (let j = 0; j < tableColsCount; j++) {
        if (labels[i] !== undefined) {
          cols.push(labels[li]);
          li++;
        } else {
          let field = {
            fid: 'b' + j,
            name: ''
          };
          cols.push(field);
          li++;
        }
      }
      rows.push({ cols });
    }
    return rows;
  };

  const fillBlankSpace = fieldCount => {
    let blankFields = Array(fieldCount).fill(0);

    const rows = blankFields.map((x, i) => {
      let field = {
        fid: x + '-' + i,
        name: ' '
      };
      return field;
    });
    return rows;
  };

  const labels = generateLabels();

  const pages = [];
  if (fullPage === true) {
    // A full page per drip
    numPages = numDrips;
    for (let p = 0; p < numPages; p++) {
      let begin = p * tableFieldCount;
      let end = (p + 1) * tableFieldCount;
      let page = pageFromLabels(p, labels.slice(begin, end));
      pages[p] = page;
    }
  } else {
    // Pages filled based on perDrug
    numPages = numFields / tableFieldCount;
    for (let p = 0; p < numPages; p++) {
      let begin = p * tableFieldCount;
      let end = (p + 1) * tableFieldCount;
      let page = pageFromLabels(p, labels.slice(begin, end));

      pages[p] = page;
    }
  }
  console.log(pages);
  return (
    <>
      <BlobProvider document={<PDFDocument pages={pages} options={options} />}>
        {({ url, blob, loading, error }) => {
          if (loading) {
            return <span>generating document...</span>;
          }

          if (!loading && url) {
            return <iframe title="pdf-drug-labels" src={url} style={{ width: '100%', height: '600px' }} />;
          }
          if (error) {
            console.error(error);
            return <p>An error occurred</p>;
          }
        }}
      </BlobProvider>
    </>
  );
};

export default Printout;
