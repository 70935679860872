import { createSelector } from 'reselect';
import { VisibilityFilters } from '../actions/visibilityActions';
export const selectAllDrips = state => state.drips;
export const selectVisibilityFilter = state => state.visibilityFilter;
export const selectOptions = state => state.options;
export const selectSidebar = state => state.sidebar;

export const selectDisplayedDrips = createSelector(selectAllDrips, selectVisibilityFilter, (drips, filter) => {
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return drips;
    case VisibilityFilters.SHOW_COMPLETED:
      return drips.filter(t => t.completed);
    case VisibilityFilters.SHOW_ACTIVE:
      return drips.filter(t => !t.completed);
    default:
      throw new Error('Unknown filter: ' + filter);
  }
});

export const selectPerDrug = {};

export const selectFullPage = {};
