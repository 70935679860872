import React from 'react';
//import Button from 'react-bootstrap/Button';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Tabs, TabNav, TabNavItem, TabContent, TabPanel } from 'react-smarttab';
import 'react-smarttab/dist/index.css';
import './Wizard.scss';

import stepzero from '../assets/images/print-help-0.png';
import stepone from '../assets/images/print-help-1.png';
import steptwo from '../assets/images/print-help-2.png';
import stepthree from '../assets/images/print-help-3.png';

const Wizard = () => {
  const TabFooter = ({ children }) => {
    return (
      <div className="toolbar" role="toolbar">
        {children}
      </div>
    );
  };

  return (
    <Tabs
      orientation="horizontal"
      theme="spark"
      justified="true"
      selected={0}
      enableURLhash={true}
      onLeaveTab={(currentIndex, nextIndex) => {
        console.log('leaveTab', currentIndex, nextIndex);
      }}
      onShowTab={e => {
        console.log('showTab', e);
      }}>
      <TabNav>
        <TabNavItem>Step 1</TabNavItem>
        <TabNavItem>Step 2</TabNavItem>
        <TabNavItem>Step 3</TabNavItem>
        <TabNavItem>Step 4</TabNavItem>
        <TabNavItem>Step 5</TabNavItem>
      </TabNav>

      <TabContent>
        <TabPanel>
          <Container fluid>
            <Row className="my-4">
              <Col>Click the "Print" icon on the Label Display.</Col>
            </Row>
            <Row className="ps-5">
              <Col>
                <img src={stepzero} alt="Printing Step 1" />
                <br />
              </Col>
            </Row>
          </Container>
        </TabPanel>
        <TabPanel>
          <Container fluid>
            <Row className="my-4">
              <Col>
                <ol>
                  <li>Select the printer you want to print to.</li>
                  <li>Ensure the options are chosen as illustrated.</li>
                  <li>Then click "Print using system dialog.."</li>
                </ol>
              </Col>
            </Row>
            <Row className="ps-5">
              <Col>
                <img src={stepone} alt="Printing Step 2" />
                <br />
              </Col>
            </Row>
          </Container>
        </TabPanel>
        <TabPanel>
          <Container fluid>
            <Row className="my-4">
              <Col>
                <ol>
                  <li>Ensure the appropriate printer is selected.</li>
                  <li>Click the "Preferences" button.</li>
                </ol>
              </Col>
            </Row>
            <Row className="ps-5">
              <Col>
                <img src={steptwo} alt="Printing Step 3" />
                <br />
              </Col>
            </Row>
          </Container>
        </TabPanel>
        <TabPanel>
          <Container fluid>
            <Row className="my-4">
              <Col>
                <ol>
                  <li>Select "Tray 1" under the "Paper Source" drop-down.</li>
                  <li>Click "Okay" to return to the Print menu.</li>
                </ol>
              </Col>
            </Row>
            <Row className="ps-5">
              <Col>
                <img src={stepthree} alt="Printing Step 4" />
                <br />
              </Col>
            </Row>
          </Container>
        </TabPanel>
        <TabPanel>
          <Container fluid>
            <Row className="my-4">
              <Col>
                <ol>
                  <li>Click the "Print" button.</li>
                  <li>Go to the selected printer and retrieve your labels.</li>
                </ol>
              </Col>
            </Row>
            <Row className="ps-5">
              <Col>
                <img src={steptwo} alt="Printing Step 5" />
                <br />
              </Col>
            </Row>
          </Container>
        </TabPanel>
      </TabContent>
    </Tabs>
  );
};

export default Wizard;
