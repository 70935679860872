import { combineReducers } from 'redux';

import sidebar from './sidebarReducers';
import theme from './themeReducer';
import drips from './dripsReducer';
import options from './optionsReducer';
import visibilityFilter from './visibilityFilter';

export default combineReducers({
  sidebar,
  theme,
  drips,
  options,
  visibilityFilter
});
