import React, { useState } from 'react';

import { Navbar, Form, Container, Col, Row, FormCheck } from 'react-bootstrap';
import { useSidebarStore, useOptionsStore } from '../redux/index';

const SidebarToggle = () => {
  const { toggleSidebar } = useSidebarStore();

  return (
    <Col xs="auto" className="my-1">
      <a className="sidebar-toggle d-flex mr-2" onClick={() => toggleSidebar()}>
        <i className="hamburger align-self-center" />
      </a>
    </Col>
  );
};

const NavbarOptions = () => {
  const { options, handleFullPage, handlePerDrug, handleSplitLabel } = useOptionsStore();
  const { fullPage, perDrug, splitLabel } = options;
  //const [splitLabel, setSplitLabel] = useState(false);
  //const [fullPage, setFullPage] = useState(false);

  const onSelectChange = e => {
    //e.preventDefault();
    //const { value, name } = perDrugEl.current;
    const value = e.target.value;

    if (!value.trim()) {
      return;
    }
    handlePerDrug(value);
  };

  const onCheckChange = e => {
    handleFullPage(!fullPage);
  };

  const onSplitChange = e => {
    handleSplitLabel(!splitLabel);
  };

  return (
    <React.Fragment>
      <Col xs="auto" className="my-1" id="perDrugContainer">
        <Form.Label className="me-sm-2" htmlFor="perDrug" visuallyHidden>
          Labels per Drug
        </Form.Label>
        <Form.Select className="me-sm-2" id="perDrug" name="perDrug" value={perDrug} onChange={onSelectChange}>
          <option value="0">Labels per Drug</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </Form.Select>
      </Col>
      <Col xs="auto" className="my-1" id="fullPageContainer">
        <FormCheck className="form-check form-check-inline">
          <FormCheck.Input type="checkbox" id="fullPage" defaultChecked={fullPage} value="1" onChange={onCheckChange} />
          <FormCheck.Label bsPrefix="form-check-label form-control-lite" htmlFor="fullPage">
            Full Page per Drug?
          </FormCheck.Label>
        </FormCheck>

        <FormCheck className="form-check form-check-inline">
          <FormCheck.Input type="checkbox" id="splitLabel" defaultChecked={splitLabel} value="1" onChange={onSplitChange} />
          <FormCheck.Label bsPrefix="form-check-label form-control-lite" htmlFor="splitLabel">
            Split the Labels?
          </FormCheck.Label>
        </FormCheck>
      </Col>
    </React.Fragment>
  );
};

const NavbarOptionsOrig = () => {
  const { options, handleFullPage, handlePerDrug, handleSplitLabel } = useOptionsStore();
  const { fullPage, perDrug, splitLabel } = options;
  const perDrugEl = React.useRef();
  const fullPageEl = React.useRef();
  const splitLabelEl = React.useRef();

  const onSelectChange = e => {
    //e.preventDefault();
    const { value, name } = perDrugEl.current;

    if (!value.trim()) {
      return;
    }
    handlePerDrug(value);
  };

  const onCheckChange = e => {
    //e.preventDefault();
    const { value, name } = fullPageEl.current;

    if (!value.trim()) {
      return;
    }
    handleFullPage(value);
  };

  const onSplitChange = e => {
    console.log('onSplitChange');
    console.log(splitLabelEl.current.value);
    console.log(e.target.value);
    const { value, name } = splitLabelEl.current;

    if (!value.trim()) {
      return;
    }
    handleSplitLabel(value);
  };

  return (
    <React.Fragment>
      <Col xs="auto" className="my-1" id="perDrugContainer">
        <Form.Label className="me-sm-2" htmlFor="perDrug" visuallyHidden>
          Labels per Drug
        </Form.Label>
        <Form.Select ref={perDrugEl} className="me-sm-2" id="perDrug" name="perDrug" value={perDrug} onChange={onSelectChange}>
          <option value="0">Labels per Drug</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </Form.Select>
      </Col>
      <Col xs="auto" className="my-1" id="fullPageContainer">
        <FormCheck className="form-check form-check-inline">
          <FormCheck.Input type="checkbox" ref={fullPageEl} id="fullPage" defaultChecked={fullPage} value="1" onChange={onCheckChange} />
          <FormCheck.Label bsPrefix="form-check-label form-control-lite" htmlFor="fullPage">
            Full Page per Drug?
          </FormCheck.Label>
        </FormCheck>

        <FormCheck className="form-check form-check-inline">
          <FormCheck.Input type="checkbox" ref={splitLabelEl} id="splitLabel" defaultChecked={splitLabel} value="1" onChange={onSplitChange} />
          <FormCheck.Label bsPrefix="form-check-label form-control-lite" htmlFor="splitLabel">
            Split the Labels?
          </FormCheck.Label>
        </FormCheck>
      </Col>
    </React.Fragment>
  );
};

const NavbarComponent = () => {
  return (
    <Navbar expand className="navbar-theme">
      <Container fluid>
        <Row className="justify-content-start">
          <React.Fragment>
            <SidebarToggle />
            <NavbarOptions />
          </React.Fragment>
        </Row>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
