import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Nav, FormCheck } from 'react-bootstrap';
import { Collapse } from 'reactstrap';

import { PulseOutline } from 'react-ionicons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import routes from '../routes/index';
import menu from '../routes/drips';
//import './Sidebar.scss';
import { useDripsStore, useSidebarStore } from '../redux/index';

const Sidebar = ({ location }) => {
  const { sidebar } = useSidebarStore();
  return (
    <nav className={classNames('sidebar', sidebar.isOpen || 'toggled')}>
      <div className="sidebar-content">
        <a className={classNames('sidebar-brand')} href="/">
          <PulseOutline color={'#5c93e3'} title={'Nursing Tools'} height="32px" width="32px" /> <span className="align-middle">Nursing Tools</span>{' '}
        </a>
        <div className="sidebar-user">
          <div className="font-weight-bold">Drip Label Maker</div>
          <small>for the ICU</small>
        </div>

        <Nav defaultActiveKey="/" className="flex-column sidebar-nav" as="ul">
          {menu.map((section, index) => {
            return (
              <React.Fragment key={index}>
                <SidebarCategory section={section} index={index} />
              </React.Fragment>
            );
          })}
        </Nav>
      </div>
    </nav>
  );
};

const SidebarCategory = ({ section, index }) => {
  const [isOpen, setCollapsed] = useState(true);
  const toggle = e => {
    setCollapsed(!isOpen);
    e.preventDefault();
  };

  return (
    <li className={'sidebar-item' + (isOpen ? '' : ' active')} key={index}>
      <a
        href={'#' + section.id}
        onClick={e => toggle(e)}
        data-bs-toggle="collapse"
        className={'sidebar-link ' + (isOpen ? 'collapsed' : '')}
        aria-expanded={!isOpen ? 'true' : 'false'}>
        <span className="align-middle">{section.header}</span>
      </a>
      <Collapse isOpen={!isOpen} tag="ul" id={section.id} className={'sidebar-dropdown list-unstyled'} key={index}>
        {section.drugs.map((drip, index) => {
          return (
            <React.Fragment key={index}>
              <SidebarItem drip={drip} index={index} />
            </React.Fragment>
          );
        })}
      </Collapse>
    </li>
  );
};
/** 
 *         <Collapse isOpen={!isOpen} tag="ul" id={section.id} className={"sidebar-dropdown list-unstyled"} key={index}>
          <div>
          <ul id={section.id} className={"sidebar-dropdown list-unstyled"} key={index}>
 * <Collapse tag="ul" isOpen={!isOpen} id={section.id} className={"sidebar-dropdown list-unstyled"}> */

/**
  *       <Form.Check type={type} id={`check-api-${type}`}>
        <Form.Check.Input type={type} isValid />
        <Form.Check.Label>{`Custom api ${type}`}</Form.Check.Label>
        <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback>
      </Form.Check>
  */
const SidebarItem = ({ drip, index }) => {
  const { drips, selectDrip } = useDripsStore();
  return (
    <li className={'sidebar-item'} key={index}>
      <FormCheck className="form-check">
        <FormCheck.Input type="checkbox" id={`gtt-${drip.id}`} onClick={() => selectDrip(drip)} value={drip.name} name={drip.id} />
        <FormCheck.Label htmlFor={`gtt-${drip.id}`}>{drip.name}</FormCheck.Label>
      </FormCheck>
    </li>
  );
};

const SidebarItemOld = ({ drip, index }) => {
  const { drips, selectDrip } = useDripsStore();
  return (
    <li className={'sidebar-item'} key={index}>
      <label className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          name={drip.id}
          value={drip.name}
          checked={drips[drip.id]}
          onClick={() => selectDrip(drip)}
          id={`gtt-${drip.id}`}
        />
        <span className="custom-control-label">{drip.name}</span>
      </label>
    </li>
  );
};

export default withRouter(Sidebar);
