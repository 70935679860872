import React, { useState, Fragment } from 'react';
import { Card, Button, Col, Container, Row, Modal } from 'react-bootstrap';
import Wizard from './Wizard';

const WizardModal = ({ show, handleClose }) => {
  return (
    <Fragment>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-wide" scrollable={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Printing Help</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Wizard />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default WizardModal;
