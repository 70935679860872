import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDripsStore } from "../redux/index";
import { Card, Button, Col, Container, Row, Modal } from "react-bootstrap";
import Printout from "../components/Printout";
import Wizard from "../components/Wizard";
import WizardModal from "../components/WizardModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import Header from "../components/Header";
import HeaderTitle from "../components/HeaderTitle";

const Main = () => {
  const { drips } = useDripsStore();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Container fluid>
        <Header>
          <HeaderTitle>Drip Label Maker</HeaderTitle>
        </Header>

        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {drips.drips.length} Drugs Selected
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Container fluid>
                  <Row>
                    <Col xs={8}></Col>
                    <Col xs={4} className="my-2" style={{ textAlign: "right" }}>
                      <Button variant="primary" onClick={handleShow}>
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          size="lg"
                          className="m-1 align-middle"
                        />
                        Printing Help
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Printout />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <WizardModal show={show} handleClose={handleClose} />
    </Fragment>
  );
};

const PrintDrips = () => {
  const { drips } = useDripsStore();
  let numDrips = drips.drips.length;
  return (
    <ul>
      {numDrips > 0 &&
        drips.drips.map((drip, index) => {
          return (
            <li key={index}>
              <b>{drip.name}</b>
            </li>
          );
        })}
    </ul>
  );
};

export default Main;
