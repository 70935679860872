import Default from "../pages/Default";
import Main from "../pages/Main";

const defaultRoutes = {
  path: "/",
  name: "Main",
  header: "Main",
  component: Main,
  children: null
};

export default [
  defaultRoutes
];