import React, { Fragment } from 'react';
import { View, Text, Document, Page, StyleSheet } from '@react-pdf/renderer';
//import { useOptionsStore } from "../redux/index";

const styles = StyleSheet.create({
  page: {},
  table: {
    display: 'table',
    width: '720px',
    height: '360px',
    marginTop: '36px',
    marginLeft: '36px',
    marginRight: '36px',
    marginBottom: '288px'
  },
  tableRow: {
    margin: 0,
    border: 0,
    padding: 0,
    flexDirection: 'row',
    height: '72px',
    width: '720px'
  },
  tableCol: {
    width: '180px',
    borderStyle: 'solid',
    borderWidth: 0
  },
  tableCell: {
    height: '72px',
    paddingVertical: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 24,
    fontStyle: 'bold',
    display: 'table-cell',
    lineHeight: '100%'
  },
  tableCellSplit: {
    height: '72px',
    paddingVertical: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontStyle: 'bold',
    display: 'table-cell',
    lineHeight: '100%'
  }
});

const PDFDocument = props => {
  const { pages, options } = props;
  //const { options } = useOptionsStore();
  const { splitLabel } = options;

  const renderDocument = pages => {
    if (pages.length > 0) {
      return (
        <Fragment>
          {pages.map((page, key) => (
            <Page size="letter" orientation="landscape" key={key} style={styles.page} wrap={true} debug={false}>
              <Table page={page} />
            </Page>
          ))}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Page size="letter" orientation="landscape" style={styles.page} wrap={true} debug={false}>
            <View style={styles.table} debug={false}></View>
          </Page>
        </Fragment>
      );
    }
  };

  const Table = ({ page }) => {
    return (
      <View style={styles.table} debug={false}>
        <TableRows items={page} />
      </View>
    );
  };

  const TableRows = ({ items }) => {
    const rows = items.map((item, iid) => (
      <View style={styles.tableRow} key={`tr-${iid}`} debug={false}>
        <TableCells cols={item.cols} />
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  /**
  const TableCells = ({ cols }) => {
    const result = cols.map((cell, index) => (
      <TableCell key={index} cell={cell} />
    ));
    return <Fragment>{result}</Fragment>;
  };
  **/
  const TableCells = ({ cols }) => {
    const result = cols.map((cell, index) => {
      if (splitLabel === true) {
        return <TableCellSplit key={index} cell={cell} />;
      } else {
        return <TableCell key={index} cell={cell} />;
      }
    });
    return <Fragment>{result}</Fragment>;
  };

  const TableCell = ({ cell }) => {
    return (
      <View style={styles.tableCol} key={`f-${cell.fid}`} debug={false}>
        <Text style={styles.tableCell} key={cell.fid} debug={false}>
          {cell.name}
        </Text>
      </View>
    );
  };

  const TableCellSplit = ({ cell }) => {
    return (
      <View style={styles.tableCol} key={`f-${cell.fid}`} debug={false}>
        <Text style={styles.tableCellSplit} key={cell.fid} debug={false}>
          {cell.name} | {cell.name}
        </Text>
      </View>
    );
  };

  return (
    <>
      <Document title="Drug Labels">{renderDocument(pages)}</Document>
    </>
  );
};

export default PDFDocument;
