export const drips = [
  {
    id: "sedation",
    header: "Sedation",
    isOpen: true,
    drugs: [
      { id: "propofol", name: "Propofol" },
      { id: "versed", name: "Versed" },
      { id: "precedex", name: "Precedex" },
      { id: "ketamine", name: "Ketamine" },
      { id: "ativan", name: "Ativan" },
      { id: "fentanyl", name: "Fentanyl" },
      { id: "dilaudid", name: "Dilaudid" },
      { id: "morphine", name: "Morphine" }
    ]
  },
  {
    id: "inopressors",
    header: "Inotropes/Pressors",
    isOpen: false,
    drugs: [
      { id: "dobutamine", name: "Dobutamine" },
      { id: "dopamine", name: "Dopamine" },
      { id: "milrinone", name: "Milrinone" },
      { id: "levophed", name: "Levophed" },
      { id: "levo", name: "Levo" },
      { id: "phenylephrine", name: "Phenylephrine" },
      { id: "neo", name: "Neo" },
      { id: "vasopressin", name: "Vasopressin" },
      { id: "vaso", name: "Vaso" },
      { id: "epinephrine", name: "Epinephrine" },
      { id: "epi", name: "Epi" }
    ]
  },
  {
    id: "cardiac",
    header: "Cardiac",
    isOpen: false,
    drugs: [
      { id: "amiodarone", name: "Amiodarone" },
      { id: "amio", name: "Amio" },
      { id: "diltiazem", name: "Diltiazem" },
      { id: "esmolol", name: "Esmolol" },
      { id: "labetalol", name: "Labetalol" },
      { id: "nicardipine", name: "Nicardipine" },
      { id: "ntg", name: "Nitroglycerin" },
      { id: "nipride", name: "Nipride" },
      { id: "isuprel", name: "Isuprel" }
    ]
  },
  {
    id: "anticoag",
    header: "Anticoag's",
    isOpen: false,
    drugs: [
      { id: "heparin", name: "Heparin" },
      { id: "bivalirudin", name: "Bivalirudin" },
      { id: "argatroban", name: "Argatroban" }
    ]
  },
  {
    id: "metabolic",
    header: "Metabolic",
    isOpen: false,
    drugs: [
      { id: "insulin", name: "Insulin" },
      { id: "bicarb", name: "Sodium Bicarb" },
      { id: "hco3", name: "HCO3" },
      { id: "bicarb", name: "Bicarb" },
      { id: "lasix", name: "Lasix" },
      { id: "tpn", name: "TPN" },
      { id: "protonix", name: "Protonix" },
      { id: "octreotide", name: "Octreotide" },
      { id: "narcan", name: "Narcan" },
      { id: "lidocaine", name: "Lidocaine" },
      { id: "veletri", name: "Veletri" }
    ]
  },
  {
    id: "paralytics",
    header: "Paralytics",
    isOpen: false,
    drugs: [
      { id: "nimbex", name: "Nimbex" },
      { id: "vecuronium", name: "Vecuronium" },
      { id: "rocuronium", name: "Rocuronium" }
    ]
  },
  {
    id: "other",
    header: "Others",
    isOpen: false,
    drugs: [
      { id: "miv", name: "MIV" },
      { id: "mivf", name: "MIVF" },
      { id: "tkoivpb", name: "TKO + IVPB" },
      { id: "tkoivpb1", name: "TKO + IVPB 1" },
      { id: "tkoivpb2", name: "TKO + IVPB 2" },
      { id: "kvo", name: "KVO" },
      { id: "bolus", name: "Bolus" },
      { id: "abx", name: "Antibiotics" },
      { id: "kcl", name: "Potassium" },
      { id: "mg", name: "Magnesium" },
      { id: "po4", name: "Sodium Phos" },
      { id: "kpo4", name: "K Phos" },
      { id: "cvp", name: "CVP" },
      { id: "pap", name: "PAP" },
      { id: "art", name: "ART" }
    ]
  }
];

export default drips;
