import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { default as defaultRoutes } from './index';
import ScrollToTop from '../components/ScrollToTop';
import DefaultLayout from '../layouts/Default';
import BlankLayout from '../layouts/Blank';
import Error404 from '../pages/Error404';

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(DefaultLayout, defaultRoutes)}
        <Route
          render={() => (
            <BlankLayout>
              <Error404 />
            </BlankLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

export default Routes;
